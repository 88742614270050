const baseUrl = "https://taxi.omoto.com.vn/taxiapi/api/";//"https://localhost:44369/api/";//"https://taxi.omoto.com.vn/taxiapi/api/";//"https://omoto.com.vn/taxiapi/api/";//"
export const ApiUserUrl =  {
    login: `${baseUrl}user/login`,
    createAccount: `${baseUrl}user/account/create`,
    updateAccount: `${baseUrl}user/account/update`,
    resetPassword: `${baseUrl}user/account/resetPassword`,
    blockAccount: `${baseUrl}user/account/block`,
}

export const CustomerApi = {
    get: `${baseUrl}customer/`,
    delete: `${baseUrl}customer/delete?id=`,
    getAll: `${baseUrl}customer/all`,
    addNew: `${baseUrl}customer/add`,
    update:`${baseUrl}customer/update`
    
}
export const DepartmentApi= {
    get: `${baseUrl}deparment/`,
    delete: `${baseUrl}deparment/delete?id=`,
    getAll: `${baseUrl}deparment/all`,
    addNew: `${baseUrl}deparment/add`,
    update:`${baseUrl}deparment/update`
}


export const CusStaffApi= {
    get: `${baseUrl}Customer/Staff/`,
    delete: `${baseUrl}Customer/Staff/delete?id=`,
    getAll: `${baseUrl}Customer/Staff/all/`,
    addNew: `${baseUrl}Customer/Staff/add`,
    update:`${baseUrl}Customer/Staff/update`
}

export const LotrinhApi= {
    get: `${baseUrl}Lotrinh/`,
    delete: `${baseUrl}Lotrinh/delete`,
    getAll: `${baseUrl}Lotrinh/all`,
    addNew: `${baseUrl}Lotrinh/add`,
    update:`${baseUrl}Lotrinh/update`,
    lotrinhToday: `${baseUrl}Lotrinh/today`,
    search: `${baseUrl}Lotrinh/search`,
    searchdiemlotrinh: `${baseUrl}Lotrinh/search/diemlotrinh`,
    exportlotrinh: `${baseUrl}Lotrinh/export/BangLotrinhThang`,
    baocaotonghop:`${baseUrl}Lotrinh/BaocaotongThang/`,
    addKhunggio: `${baseUrl}Lotrinh/Khunggio/add`,
    updateKhunggio:`${baseUrl}Lotrinh/Khunggio/update`,
    deleteKhunggio:`${baseUrl}Lotrinh/Khunggio/delete`,
    getAllKhunggio:`${baseUrl}Lotrinh/Khunggio/getAll`,
    banglotrinchitiet: `${baseUrl}Lotrinh/Banglotrinhchitiet/`,
    getLotrinhListView: `${baseUrl}Lotrinh/GetLoTrinhListView`,
    getLotrinhDetail: `${baseUrl}Lotrinh/GetLotrinh?lotrinhid=`,
    checkin:`${baseUrl}Lotrinh/Checkin`,
    confirm:`${baseUrl}Lotrinh/Confirm`,
    adminUpdate: `${baseUrl}lotrinh/UpdateByAdmin`,
    generateDataForSale: `${baseUrl}lotrinh/GenerateDataForSale`,
}

export const ApiMaster = {
    getProvinces: `${baseUrl}masterdata/GetProvinces`,
    getDistricts: `${baseUrl}masterdata/GetDistricts`,
    getWards: `${baseUrl}masterdata/GetWards`,
    syncDistricts: `${baseUrl}masterdata/SyncDistricts`,
    syncWards:`${baseUrl}masterdata/SyncWards`,
    
}

export const apiStaff = {
    add: `${baseUrl}staff/add`,
    update:`${baseUrl}staff/update`,
    delete:`${baseUrl}staff/delete?id=`,
    getAll: `${baseUrl}staff/all`,
    getAllInDept: `${baseUrl}staff/allIndeparment`,
    getStaff: `${baseUrl}staff/`,
    search: `${baseUrl}staff/search/`,
    getWorkingPlan: `${baseUrl}Sale/getWorkingPlan`,
    updateWorkingPlan: `${baseUrl}Sale/updateWorkingPlan`,
    resetPassword: `${baseUrl}user/account/resetPassword`,
    blockAccount: `${baseUrl}user/account/block`,
    driverAvailable: `${baseUrl}staff/DriverAvailable?excudedId=`
}

export const KhuVucApi= {
    get: `${baseUrl}KhuVuc/`,
    delete: `${baseUrl}KhuVuc/delete?id=`,
    getAll: `${baseUrl}KhuVuc/all`,
    addNew: `${baseUrl}KhuVuc/add`,
    update:`${baseUrl}KhuVuc/update`,
    khuvucMaster: `${baseUrl}Khuvuc/KhuvucMaster`,
    setKhuvucAdmin: `${baseUrl}Khuvuc/KhuvucMaster/setAdmin`,
    addSubKhuvuc: `${baseUrl}Khuvuc/subKhuvuc/add`,
    updateSubKhuvuc:`${baseUrl}Khuvuc/subKhuvuc/update`,
    deleteSubKhuvuc:`${baseUrl}Khuvuc/subKhuvuc/delete`,
    getAllSubKhuvuc:`${baseUrl}Khuvuc/subKhuvuc/getAll`,
    addDongia:`${baseUrl}KhuVuc/dongia/add`,
    updateDongia:`${baseUrl}KhuVuc/dongia/update`,
    deleteDongia:`${baseUrl}KhuVuc/dongia/delete`
}

export const ExportApi = {
    banglotrinhchitietPDF: `${baseUrl}report/banglotrinhchitiet/`,
    baocaotonghopPDF: `${baseUrl}report/Baocaotonghop/`,
    baocaotonghopExcel: `${baseUrl}report/Baocaotonghop/excel`,
    banglotrinhchitietExcel: `${baseUrl}report/BanglotrinhChitiet/excel/`,
    sale_working_plan: `${baseUrl}report/SaleWorkingPlan/excel/`
}

export const LeavingApi = {
    addHolidays: `${baseUrl}leaving/addHolidays`,
    removeHolidays: `${baseUrl}leaving/removeHolidays`,
    getLeaveTypes: `${baseUrl}leaving/leaveTypes`,
    getHolidays: `${baseUrl}leaving/getHolidays`
}